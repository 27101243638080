.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: -webkit-transform 2s;
  transition: transform 2s;
  transition: transform 2s, -webkit-transform 2s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  font-family: "Helvetica Neue", sans-serif;
  line-height: 1.1em;
}
.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
  text-align: center;
}

.style_lds-circle__3OA87 {
    display: inline-block;
    margin: 8px;
    border-radius: 50%;
    -webkit-animation: style_lds-circle__3OA87 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: style_lds-circle__3OA87 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
@-webkit-keyframes style_lds-circle__3OA87 {
    0%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
              animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(1800deg);
              transform: rotateY(1800deg);
      -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
              animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      -webkit-transform: rotateY(3600deg);
              transform: rotateY(3600deg);
    }
}
@keyframes style_lds-circle__3OA87 {
    0%, 100% {
      -webkit-animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
              animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
    }
    50% {
      -webkit-transform: rotateY(1800deg);
              transform: rotateY(1800deg);
      -webkit-animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
              animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      -webkit-transform: rotateY(3600deg);
              transform: rotateY(3600deg);
    }
}
.style_lds-default__WmK6_ {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-default__WmK6_ div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: style_lds-default__WmK6_ 1.2s linear infinite;
          animation: style_lds-default__WmK6_ 1.2s linear infinite;
}
.style_lds-default__WmK6_ div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 46.25%;
  left: 82.5%;
}
.style_lds-default__WmK6_ div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 27.5%;
  left: 77.5%;
}
.style_lds-default__WmK6_ div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 13.75%;
  left: 65%;
}
.style_lds-default__WmK6_ div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 8.75%;
  left: 46.25%;
}
.style_lds-default__WmK6_ div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 13.75%;
  left: 27.5%;
}
.style_lds-default__WmK6_ div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 27.5%;
  left: 13.75%;
}
.style_lds-default__WmK6_ div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 46.25%;
  left: 8.75%;
}
.style_lds-default__WmK6_ div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 65%;
  left: 13.75%;
}
.style_lds-default__WmK6_ div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 77.5%;
  left: 27.5%;
}
.style_lds-default__WmK6_ div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 82.5%;
  left: 46.25%;
}
.style_lds-default__WmK6_ div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 77.5%;
  left: 65%;
}
.style_lds-default__WmK6_ div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 65%;
  left: 77.5%;
}
@-webkit-keyframes style_lds-default__WmK6_ {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
@keyframes style_lds-default__WmK6_ {
  0%,
  20%,
  80%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.style_lds-dual-ring__3EeGx {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.style_lds-dual-ring-after__3A7ir {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  -webkit-animation: style_lds-dual-ring__3EeGx 1.2s linear infinite;
          animation: style_lds-dual-ring__3EeGx 1.2s linear infinite;
}
@-webkit-keyframes style_lds-dual-ring__3EeGx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-dual-ring__3EeGx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ellipsis__3Mws9 {
    display: inline-block;
    position: relative;
  }
  .style_lds-ellipsis__3Mws9 div {
    position: absolute;
    top: 41.25%;
    width: 16.25%;
    height: 16.25%;
    border-radius: 50%;
    background: #fff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .style_lds-ellipsis__3Mws9 div:nth-child(1) {
    left: 10%;
    -webkit-animation: style_lds-ellipsis1__2hQDs 0.6s infinite;
            animation: style_lds-ellipsis1__2hQDs 0.6s infinite;
  }
  .style_lds-ellipsis__3Mws9 div:nth-child(2) {
    left: 10%;
    -webkit-animation: style_lds-ellipsis2__1-eee 0.6s infinite;
            animation: style_lds-ellipsis2__1-eee 0.6s infinite;
  }
  .style_lds-ellipsis__3Mws9 div:nth-child(3) {
    left: 40%;
    -webkit-animation: style_lds-ellipsis2__1-eee 0.6s infinite;
            animation: style_lds-ellipsis2__1-eee 0.6s infinite;
  }
  .style_lds-ellipsis__3Mws9 div:nth-child(4) {
    left: 70%;
    -webkit-animation: style_lds-ellipsis3__11gKq 0.6s infinite;
            animation: style_lds-ellipsis3__11gKq 0.6s infinite;
  }
  @-webkit-keyframes style_lds-ellipsis1__2hQDs {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes style_lds-ellipsis1__2hQDs {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes style_lds-ellipsis3__11gKq {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes style_lds-ellipsis3__11gKq {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes style_lds-ellipsis2__1-eee {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(184.61%, 0);
              transform: translate(184.61%, 0);
    }
  }
  @keyframes style_lds-ellipsis2__1-eee {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(184.61%, 0);
              transform: translate(184.61%, 0);
    }
  }
.style_lds-facebook__2Bfhz {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .style_lds-facebook__2Bfhz div {
    display: inline-block;
    position: absolute;
    left: 10%;
    width: 20%;
    background: #fff;
    -webkit-animation: style_lds-facebook__2Bfhz 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: style_lds-facebook__2Bfhz 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .style_lds-facebook__2Bfhz div:nth-child(1) {
    left: 10%;
    -webkit-animation-delay: -0.24s;
            animation-delay: -0.24s;
  }
  .style_lds-facebook__2Bfhz div:nth-child(2) {
    left: 40%;
    -webkit-animation-delay: -0.12s;
            animation-delay: -0.12s;
  }
  .style_lds-facebook__2Bfhz div:nth-child(3) {
    left: 70%;
    -webkit-animation-delay: 0;
            animation-delay: 0;
  }
  @-webkit-keyframes style_lds-facebook__2Bfhz {
    0% {
      top: 10%;
      height: 80%;
    }
    50%, 100% {
      top: 30%;
      height: 40%;
    }
  }
  @keyframes style_lds-facebook__2Bfhz {
    0% {
      top: 10%;
      height: 80%;
    }
    50%, 100% {
      top: 30%;
      height: 40%;
    }
  }

.style_lds-grid__2rf96 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-grid__2rf96 div {
  position: absolute;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: #fff;
  -webkit-animation: style_lds-grid__2rf96 1.2s linear infinite;
          animation: style_lds-grid__2rf96 1.2s linear infinite;
}
.style_lds-grid__2rf96 div:nth-child(1) {
  top: 10%;
  left: 10%;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.style_lds-grid__2rf96 div:nth-child(2) {
  top: 10%;
  left: 40%;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-grid__2rf96 div:nth-child(3) {
  top: 10%;
  left: 70%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2rf96 div:nth-child(4) {
  top: 40%;
  left: 10%;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-grid__2rf96 div:nth-child(5) {
  top: 40%;
  left: 40%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2rf96 div:nth-child(6) {
  top: 40%;
  left: 70%;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.style_lds-grid__2rf96 div:nth-child(7) {
  top: 70%;
  left: 10%;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-grid__2rf96 div:nth-child(8) {
  top: 70%;
  left: 40%;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.style_lds-grid__2rf96 div:nth-child(9) {
  top: 70%;
  left: 70%;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
@-webkit-keyframes style_lds-grid__2rf96 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes style_lds-grid__2rf96 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.style_lds-heart__Cjoe1 {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
  }
  .style_lds-heart__Cjoe1 > div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 32px;
    height: 32px;
    background: #fff;
    -webkit-animation: style_lds-heart__Cjoe1 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
            animation: style_lds-heart__Cjoe1 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .style_lds-heart__Cjoe1 .style_div-after__1cGGr,
  .style_lds-heart__Cjoe1 .style_div-before__328nI {
    content: " ";
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: #fff;
  }
  .style_lds-heart__Cjoe1 .style_div-before__328nI {
    left: -24px;
    border-radius: 50% 0 0 50%;
  }
  .style_lds-heart__Cjoe1 .style_div-after__1cGGr {
    top: -24px;
    border-radius: 50% 50% 0 0;
  }
  @-webkit-keyframes style_lds-heart__Cjoe1 {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    5% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    39% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    60% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
  @keyframes style_lds-heart__Cjoe1 {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    5% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    39% {
      -webkit-transform: scale(0.85);
              transform: scale(0.85);
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    60% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
  }
.style_lds-hourglass__2g0AP {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-hourglass-after__2qY4w {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: style_lds-hourglass__2g0AP 1.2s infinite;
          animation: style_lds-hourglass__2g0AP 1.2s infinite;
}
@-webkit-keyframes style_lds-hourglass__2g0AP {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}
@keyframes style_lds-hourglass__2g0AP {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(900deg);
            transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
  }
}

.style_lds-orbitals__33WTP {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-orbitals__33WTP * {
  --center: translate(-50%, -50%);
}
.style_lds-orbitals__33WTP .style_center__2tp7- {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: var(--center);
          transform: var(--center);
}
.style_lds-orbitals__33WTP .style_outer-spin__3YSTU,
.style_lds-orbitals__33WTP .style_inner-spin__1sDYn {
  position: absolute;
  top: 50%;
  left: 50%;
}
.style_lds-orbitals__33WTP .style_inner-arc__fIQOE {
  position: absolute;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 3px solid;
}
.style_lds-orbitals__33WTP .style_inner-arc_start-a__2ZZFO {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg);
          transform: var(--center) rotate(45deg);
}
.style_lds-orbitals__33WTP .style_inner-arc_end-a__bqWk9 {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(25deg);
          transform: var(--center) rotate(25deg);
}
.style_lds-orbitals__33WTP .style_inner-moon-a__2R-Wb {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(17px, 0);
          transform: var(--center) translate(17px, 0);
}
.style_lds-orbitals__33WTP .style_inner-moon-b__7mVE9 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(-17px, 0);
          transform: var(--center) translate(-17px, 0);
}
.style_lds-orbitals__33WTP .style_inner-arc_start-b__u6Fye {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg) scale(-1, -1);
          transform: var(--center) rotate(65deg) scale(-1, -1);
}
.style_lds-orbitals__33WTP .style_inner-arc_end-b__lcS3x {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg) scale(-1, -1);
          transform: var(--center) rotate(45deg) scale(-1, -1);
}
.style_lds-orbitals__33WTP .style_outer-arc__3K5K0 {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid;
}
.style_lds-orbitals__33WTP .style_outer-arc_start-a__38Sto {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg);
          transform: var(--center) rotate(65deg);
}
.style_lds-orbitals__33WTP .style_outer-arc_end-a__31fIz {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg);
          transform: var(--center) rotate(45deg);
}
.style_lds-orbitals__33WTP .style_outer-moon-a__3uOzn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(32px, 0);
          transform: var(--center) translate(32px, 0);
}
.style_lds-orbitals__33WTP .style_outer-moon-b__b8eAH {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  -webkit-transform: var(--center) translate(-32px, 0);
          transform: var(--center) translate(-32px, 0);
}
.style_lds-orbitals__33WTP .style_outer-arc_start-b__2eX0u {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(65deg) scale(-1, -1);
          transform: var(--center) rotate(65deg) scale(-1, -1);
}
.style_lds-orbitals__33WTP .style_outer-arc_end-b__3BF7r {
  border-color: transparent transparent transparent;
  -webkit-transform: var(--center) rotate(45deg) scale(-1, -1);
          transform: var(--center) rotate(45deg) scale(-1, -1);
}
.style_lds-orbitals__33WTP .style_outer-spin__3YSTU {
  -webkit-animation: style_spin__2BfhH 4s linear infinite;
          animation: style_spin__2BfhH 4s linear infinite;
}
.style_lds-orbitals__33WTP .style_inner-spin__1sDYn {
  -webkit-animation: style_spin__2BfhH 3s linear infinite;
          animation: style_spin__2BfhH 3s linear infinite;
}
@-webkit-keyframes style_spin__2BfhH {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_spin__2BfhH {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ring__AieBx {
  display: inline-block;
  position: relative;
}
.style_lds-ring__AieBx div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: style_lds-ring__AieBx 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: style_lds-ring__AieBx 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.style_lds-ring__AieBx div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.style_lds-ring__AieBx div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.style_lds-ring__AieBx div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes style_lds-ring__AieBx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-ring__AieBx {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-ripple__1bDR5 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.style_lds-ripple__1bDR5 div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: style_lds-ripple__1bDR5 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: style_lds-ripple__1bDR5 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.style_lds-ripple__1bDR5 div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

@-webkit-keyframes style_lds-ripple__1bDR5 {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}

@keyframes style_lds-ripple__1bDR5 {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}

.style_lds-roller__24q1V {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-roller__24q1V > div {
  -webkit-animation: style_lds-roller__24q1V 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: style_lds-roller__24q1V 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.style_lds-roller__24q1V div .style_div-after__15bvr {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.style_lds-roller__24q1V div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.style_lds-roller__24q1V div:nth-child(1) .style_div-after__15bvr {
  top: 63px;
  left: 63px;
}
.style_lds-roller__24q1V div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.style_lds-roller__24q1V div:nth-child(2) .style_div-after__15bvr {
  top: 68px;
  left: 56px;
}
.style_lds-roller__24q1V div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.style_lds-roller__24q1V div:nth-child(3) .style_div-after__15bvr {
  top: 71px;
  left: 48px;
}
.style_lds-roller__24q1V div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.style_lds-roller__24q1V div:nth-child(4) .style_div-after__15bvr {
  top: 72px;
  left: 40px;
}
.style_lds-roller__24q1V div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.style_lds-roller__24q1V div:nth-child(5) .style_div-after__15bvr {
  top: 71px;
  left: 32px;
}
.style_lds-roller__24q1V div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.style_lds-roller__24q1V div:nth-child(6) .style_div-after__15bvr {
  top: 68px;
  left: 24px;
}
.style_lds-roller__24q1V div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.style_lds-roller__24q1V div:nth-child(7) .style_div-after__15bvr {
  top: 63px;
  left: 17px;
}
.style_lds-roller__24q1V div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.style_lds-roller__24q1V div:nth-child(8) .style_div-after__15bvr {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes style_lds-roller__24q1V {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes style_lds-roller__24q1V {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.style_lds-spinner__2S9bo {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.style_lds-spinner__2S9bo div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: style_lds-spinner__2S9bo 1.2s linear infinite;
          animation: style_lds-spinner__2S9bo 1.2s linear infinite;
}
.style_lds-spinner__2S9bo div .style_div-after__3JtrH {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.style_lds-spinner__2S9bo div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.style_lds-spinner__2S9bo div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.style_lds-spinner__2S9bo div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.style_lds-spinner__2S9bo div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.style_lds-spinner__2S9bo div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.style_lds-spinner__2S9bo div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.style_lds-spinner__2S9bo div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.style_lds-spinner__2S9bo div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.style_lds-spinner__2S9bo div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.style_lds-spinner__2S9bo div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.style_lds-spinner__2S9bo div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.style_lds-spinner__2S9bo div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes style_lds-spinner__2S9bo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes style_lds-spinner__2S9bo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.style_lds-ouroboro__-n3M8 {
  position: relative;
  display: inline-block;
  height: 64px;
  width: 64px;
  margin: 0.5em;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.style_lds-ouroboro__-n3M8:after {
  content: '';
  position: relative;
  top: 15%;
  left: 15%;
  display: block;
  height: 70%;
  width: 70%;
  background: none repeat scroll 0 0 #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.style_lds-ouroboro__-n3M8 > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}
.style_lds-ouroboro__-n3M8 > .style_left__23q90 {
  left: 0;
}
.style_lds-ouroboro__-n3M8 > .style_right__D-blK {
  left: 50%;
}

.style_lds-ouroboro__-n3M8 > .style_left__23q90 > .style_anim__2x5lv,
.style_lds-ouroboro__-n3M8 > .style_right__D-blK > .style_anim__2x5lv {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #508ec3;
  -webkit-animation: style_lds-ouroboro-rotate__2Vg9r 3s infinite;
          animation: style_lds-ouroboro-rotate__2Vg9r 3s infinite;
  opacity: 0.8;
  -webkit-transform-origin: 0 50% 0;
          transform-origin: 0 50% 0;
}
.style_lds-ouroboro__-n3M8 > .style_left__23q90 > .style_anim__2x5lv {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.style_lds-ouroboro__-n3M8 > .style_right__D-blK > .style_anim__2x5lv {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
          transform-origin: 100% 50% 0;
}

@-webkit-keyframes style_lds-ouroboro-rotate__2Vg9r {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes style_lds-ouroboro-rotate__2Vg9r {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

